.cards-wrapper {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    padding: 2% 0%;
    padding-left: 6% !important;

}



.bordered-card-name {
    border-bottom: 2px solid #D8D8D8;
    width: 18vw !important;
    text-align: left;
    padding-bottom: 1vh;
    color: #5E5E5E;
    font-weight: bold;
}

.name-and-card-number p{
    text-align: left;
    margin-left: 3vw;
    color: #7D7D7D;
}
.name-and-card-number p:first-child {
    padding-top: 1vh;
}

.no-cards {
    font-family: "Comfortaa";
    color: #A8A8A8;
}