.left {
    margin-right: -10%;
}

.right  {
    margin-left: -10%;
}

.subscriptions-images {
    height: 45vh;
    margin-left: 2%;
}

.subscriptions-images > img {
    max-height: 100%;
}

.no-subscription-info {
    height: 45vh;
}



.white-circle {

    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.2);
    width: 164px;
    height: 164px;
    margin-left: 3vw;
}

.subicon {
    padding-top: 6vh;
}

.chevron {
    color: black;
}

.cards-info-wrapper {
    margin-top: 1%;
    margin-left: 15%;
}

.carousel-indicators {

}

.cards-info-wrapper > .carousel > ol {
    bottom: -7vh !important;
}

.carousel-indicators li {
    background: #7D7D7D !important;
    border: none !important;
}
