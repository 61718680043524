.sp-background {
    background: url(../../assets/sp-bgr.png) center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.heading-title {
    font-size: 6em;
    color: #FFFFFE;
    font-weight: bold;
    line-height: 1;
}

.heading-body {
    width: 25vw;
    color: #FFFFFE;
    font-weight: bold;
}

.heading-wrapper {
    top: 25vh;
    left: 7vw;
}

.plans-row {
    margin-top: 8vh;
    margin-left: 16vw;
}


.silver-frame-wrapper {
    background: url(../../assets/silver-frame.png) center center no-repeat;
    min-width: 11vw;
    height: 34vh;

}

.gold-frame-wrapper {
    background: url(../../assets/gold-frame.png) center center  no-repeat;
    min-width: 11vw;
    height: 34vh;

}

.platinum-frame-wrapper {
    background: url(../../assets/platinum-frame.png) center center no-repeat;
    min-width: 11vw;
    height: 34vh;
}

.icon-star {
    padding-top: 10vh;
}

.icon-star-plat {
    padding-top: 6vh !important;
}

.shape {
    padding-top: 1vh;
}

.plan-name {
    text-align: center;
    color: white;
    font-size: 2.3em;
    font-weight: bold;
    margin-top: 5vh;
    text-transform: uppercase;
}

.price-with-plan {
    font-weight: bold;
    line-height: 1;
}

.green-energy-text {
    line-height: 0;
    color: #555555;
    font-weight: bold;
}

.pref-price {
    font-size: 1.7em;
}

.pref-price-euro-sign {
    font-size: 0.5em;
    vertical-align: top;
    font-weight: bold;
}

.plan-price {
    font-size: 3em;
}

.plan-price-euro-sign {
    line-height: 1.7;
    font-size: 0.7em;
    vertical-align: top;
    color: #555555;
}

.subscribe-btn {
    background: none;
    border: none;
}

.subscribe-btn:focus {
    outline: none;
}
.subscribe-btn:hover {
    opacity: 0.8;
}

.subscribe-btn-text {
    color: #4E5EFC;
    font-weight: bolder;
}

.font-wrap {
    font-family: "Rajdhani";
}

.spinner-sp {
    margin-top: 25vh;
    margin-left: 35vw;
}


