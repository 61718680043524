.section-wrapper {
    background: url(../../../assets/home-bgr.png) no-repeat center;
    background-size: cover;
    height: 100%;
}

.section-heading {
    padding-top: 31vh;
    font-size: 3em;
    font-weight: bold;
    color: white;
}

.section-text p {
    width: 27vw;
    color: white;
}

.read-more-btn-wrapper {
    padding-top: 8vh;
    bottom: 0 !important;
    left: 0
}


.read-more-btn {
    background: none;
    width: 176px;
    height: 54px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    border-radius: 6px;
    color: white;
    font-weight: bold;
}

.read-more-btn:focus {
    outline: none;
}

.charge-types {
    margin-top: 19vh;
}
