.edit-delete-links {
    margin-top: 10vh;
}

.edit-delete-links button {
    background: none;
    border: none;
}

.edit-vehicle {
    color: #4E5EFC;
    font-weight: bold;
    cursor: pointer;
}

.edit-vehicle img {
    padding-bottom: 1.3vh !important;
}

.edit-vehicle span {
    padding-left: 4%;
}

.remove-vehicle span {
    padding-left: 3%;
}

.remove-vehicle {
    margin-top: 3vh;
    color: #FF4A86;
    width: 15vw;
    font-weight: bold;
}

.chev-right {
    margin-left: 5vw;
}

.chev-left {
    margin-right: 5vw;
}

.chev-right > button {
    background: url("../../assets/chev-right-prefs.svg");
    width: 18px;
    height: 33px;
    border: none;
}

.chev-left > button {
    background: url("../../assets/chevron-left.svg");
    width: 18px;
    height: 33px;
    border: none;
}

.car-name {
    font-family: 'Rajdhani', sans-serif;
    color: #555555;
    font-size: 2.5em;
}

.license-plate {
    font-family: 'Rajdhani', sans-serif;
    color: #4E5EFC;
    font-weight: bold;
    font-size: 1.2em;
}

.cars-button:focus {
    outline: none;
}

.cars-button:disabled {
    opacity: 0.5;
}

.car-image-wrapper {
    max-width: 35.729166666666664vw;
    height: 21.82377049180328vh;
}

.car-inputs-wrapper {
    margin-top: 10vh;
}

.car-inputs {
    margin-left: 10vw;
}

.defaultChargingValue {
    width: 9vw;
    background: none;
    border: none;
    padding-left: 15%;
    color: #7D7D7D;
    font-size: 1.3em;
}

#file {
    display: none;
}

.carousel-indicators li {
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    margin-left: 2% !important;
    background: white !important;
    border: 1px solid #555555 !important;
    cursor: unset !important;
}

.carousel-indicators li.active {
    background: #555555 !important;
}

#chargingValue::-webkit-inner-spin-button {
    height: 5vh;
}


.defaultChargingValue::-webkit-inner-spin-button {
    opacity: 1
}


.defaultChargingValue::-webkit-calendar-picker-indicator {
    visibility: hidden;
}

input:focus {
    outline: none;
}

.charge-input {
    border-bottom: 1px solid #A8A8A8;
}

.fa-chevron-down {
    color: #7D7D7D;
}

.cv-label {
    margin-left: -2vw;
    z-index: -1;
}

.defaultChargingValue {
    cursor: pointer;
}

.loading-spinner-prefs > .spinner-border {
    width: 5rem;
    height: 5rem;
}