.footer-container {
    background: #272727;
    color: white;
}

.footer-text {
    color: #5E5E5E;
}

.headquarters-text {
    color: #A8A8A8;
}

.email-input {
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #33FDDB;
    color: #33FDDB;
}

.newsletter-subscribe-button {
    font-size: 1.5em;
    margin-left: 2%;
    background: none;
    color: #0062FF;
    border: 2px solid #0062FF;
    border-radius: 5px;

}

.divider-span {
    margin-top: 1%;
    border-top: 1px solid grey;
}

.footer-link {
    color: white;
}

.footer-link:hover {
    text-decoration: none;
}

.footer-socials a:hover {
    text-decoration: none;
}
