.socialButtonGoogle {
    border: none;
    border-radius: 50%;
    padding-top: 0;
}

.socialButtonGoogle > img {
    padding: 5px 0;
}

.socialButtonGoogle:focus {
    outline: none;
}

.socialButton {
    border: none;
    background: none;
}

.socialButton:focus {
    outline: none;
}