.header-img {
    width: 100%;
}

.sc-plan-price {
    color: #5E5E5E;
    font-size: 20em;
    margin-left: 5vw;
}

.sc-plan-price p {
    font-size: 100px;
    margin-top: 5vh;
}

.sc-sub-btn {
    color: white;
    font-weight: bold;
    font-size: 4em;
    border: none;
    height: 8vh;
    background: url(../../../assets/button-background.svg);
    background-size: cover;
    width: 80%;

}

.gpay {
    font-size: 15em;
    vertical-align: center;
}

.gpay-img {
    width: 50vw;
}

.gpay-wrapper {
    width: 65%;
    border: 10px solid black;
    border-radius: 5rem;
}