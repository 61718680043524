.Plan1 {
    background: url(../../../assets/silver-bgr.png);
}

.Plan2 {
    background: url(../../../assets/gold-bgr.png);
}

.Plan3 {
    background: url(../../../assets/plat-bgr.png);
}

.sub-plans {
    padding-left: 7vw !important;
}

.ms-plan {
    width: 250px;
    height: 36vh;
    opacity: 0.2;
}

.ms-plan:hover {
    opacity: 1;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25);
    font-size: 1.2em;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid grey;
}

.msp-name {
    text-align: center;
    color: white;
    font-size: 2.3em;
    font-weight: bold;
    margin-top: 5vh;
    text-transform: uppercase;
}

.msp-icon {
    padding-top: 10vh;
}

.msp-icon-plat {
    padding-top: 6vh !important;
}
