.input-date::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(../../assets/icon_calendar.svg);
    width: 1vw;
    height: 20px;
    cursor: pointer;
}

.input-date::-webkit-inner-spin-button,
.input-date::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
}

.input-date {
    background: none;
    min-height: 4vh;

    border: 2px solid blue;
    border-radius: 5px;
    color: blue;
}


.span-from-date {
    border: 2px solid blue;
    border-right: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 2.39%;
    margin-right: -19%;
    color: blue;
}

.div-from {
    height: 2vh;
}

.span-to-date {
    border: 2px solid blue;
    border-right: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 2.5%;
    padding-left: 2%;
    margin-right: -12%;
    color: blue;
}

#dateFrom::-webkit-inner-spin-button {
    z-index: -1;
}





