.profile-update-btn {
    margin-left: 5%;
    margin-top: 5%;
    background: none;
    border: 2px solid #4E5EFC;
    border-radius: 5px;
    width: 15vw;
    height: 6vh;
    padding:2%;
    color: #4E5EFC;
    font-weight: bold;
}

.profile-update-btn:disabled {
    opacity: 0.5;
}

