.box-wrapper {
    height: 450px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25);
    font-family: "Rajdhani";
}

.box-heading {
    padding-top: 3vh;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
}

.icon-wrapper {
    height: 12vh;
}

.learn-more-btn-wrapper {
    padding-top: 8vh;
    bottom: 0 !important;
    left: 0
}


.learn-more-btn {
    background: none;
    width: 176px;
    height: 54px;
    border-width: 2px;
    border-color: rgb(85, 85, 85);
    border-style: solid;
    border-radius: 6px;
    color: rgb(85, 85, 85);
    font-weight: bold;
}

.learn-more-btn:focus {
    outline: none;

}