.text-blue {
    color: #4E5EFC;
}

.input-field {
    padding-left: 3%;
    border: none;
    border-bottom: 2px solid #D8D8D8;
    width: 30vw;
    color: #7D7D7D;
}

.select-wrapper > select {
    padding-left: 3%;
    background: none;
    border: none;
    border-bottom: 2px solid #D8D8D8;
    color: #7D7D7D;
}

.lang-dropdown {
    background: none;
    border: none;
    border-bottom: 2px solid #D8D8D8;
    width: 30vw;
    color: #7D7D7D;
}

.lang-btn{
    background: none;
    border:none;
    width: 30vw;
    text-align: left;
    padding-left: 3%;
}

.lang-btn:focus {
    outline: none;
}

.lang-dd {
    padding: 0 !important;
    width: 98%;
    border-top: none !important;
    border-radius: 0 !important;
}

#prof-select {
    background: url(../../assets/chevron-down-solid.svg) no-repeat right ;
    -webkit-appearance: none;
    background-position-x: 98.6%;
}

.select-field:after {
    content: none;
}

.select-field {
    width: 30vw;
}


.labels {
    color: #5E5E5E;
    font-weight: bold;
}


#dateOfBirth::-webkit-inner-spin-button,
#dateOfBirth::-webkit-outer-spin-button {
    visibility: hidden;
}

#dateOfBirth::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(../../assets/chevron-down-solid.svg);
    width: 0.3vw;
    height: 1vh;
}

.update-form-modal .modal-body {
    background: #F1F1F1;
}

.up-trigger-btn {
    background: none;
    border: none;
}

.profile-calendar {
    width: 30vw;
    border: none;
}

.profile-calendar > .react-date-picker__wrapper {
    border: none;
    border-bottom: 2px solid #D8D8D8;
}

.profile-calendar > .react-date-picker__wrapper > .react-date-picker__inputGroup input {

    color: #7D7D7D !important;
}

.profile-calendar > .react-date-picker__wrapper > .react-date-picker__inputGroup:first-child {
    padding-left: 5%;
}

.profile-calendar > .react-date-picker__wrapper > .react-date-picker__inputGroup > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    width: auto !important;
}

.profile-calendar > .react-date-picker__wrapper > .react-date-picker__inputGroup .react-date-picker__inputGroup__leadingZero {
    color: #7D7D7D;
}
.flags-dd {
    color: #7D7D7D;
}

.flags-dd > img:first-child{
    width: 1.5vw;
}



