.carousel-item > img {
    height: 95vh;
    width: 100%;
}

.carousel > ol {
    margin-bottom: 5vh;
}

.socials-home {
    min-width: 20vw;
    position: relative;
    margin-top: -4vh;
    margin-right: 1vw;
    float: right;
    z-index: 1;
}

.socials-home > a:hover {
    text-decoration: none;
}


.socials-home-text {
    margin-right: 4vw;
    color: #7D7D7D;
    font-weight: bold;
    font-size: 0.8em;
}

.socials-home-image {
    padding-right: 1vw;
}

.slide-info-container {
    margin-left: 10vw;
    position: absolute;
    top: 34vh;
}

.slide-info-header {
    font-size: 4em;
    font-weight: bold;
    color: #A8A8A8;
    line-height: 1;
}

.slide-info-text {
    width: 26vw;
    color: #A8A8A8;
}

.btn-home-learnMore {
    background: none;
    border: 2px solid white;
    border-radius: 6px;
    width: 276px;
    height: 54px;
    color: white;
}

.home-scroll-down {
    line-height: 3;
    margin-left: 2vw;
    color: #A8A8A8;
}

.home-scroll-down img {
    width: 1vw;
    margin-right: 1vh;
}

.carousel-control-prev, .carousel-control-next {
    width: 8% !important;
}