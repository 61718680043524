.nexton-background {
    background: url("../../assets/bitmap.png") no-repeat;
    height: 169px;
}

.socials {
    margin-top: -30px;
}

.socials > div > span {
    margin-right: 15%;
}
.socials > div > span:nth-child(1) {
    margin-left: 15%;
}

.inputField {
    border: none !important;
}

.inputField:focus {
    border: none !important;
    outline: none !important;
}

.inputBottomLine {
    width: 100% !important;
    border-bottom: 2px solid #D8D8D8;
}

.styledText {
    font-size: 2.8em;
    color: white;
}

.forgottenPasswordSpan {
    padding-top: 15%;
}

.forgottenPasswordLink {
    padding-top: 10%;
    border: none;
    background: none;
    color: #0062FF !important;
}

.forgottenPasswordWrapper {
    float: left;
    margin-left: 10%;
    padding-top: 2%;
}

.forgottenPasswordLink:focus {
    outline: none !important;
}

.loginSpan {
    margin-left: 12%;
    float: left;
}

.loginButton {
    width: 10vw;
    height: 5vh;
    border: 2px solid #0062FF;
    border-radius: 5px;
    background: none;
    color: #0062FF;
}

.createUser {
    border:none ;
    height: 8vh;
    background: linear-gradient(to bottom right, #18D4B3, #2BC9EF);
    width: 100%;
}

.createText {
    font-size: 1.2em;
    color:white;
}

.form-bgr {
    background: white;
}

.imgSpan {
    width: 60px;
    padding-left: 20px;
}

.mailIcon {
    padding-top:10px;
}

