
.sup-wrapper {
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25);
}

.chevron-right {
    width: 0.4vw;
}

.support-info {
    color: #4E5EFC;
}

