
.ct-icon-wrapper {
    height: 10vh;
}

.ct-wrapper {
    padding-right: 2vw;
}

.ct-text {
    color: white;
    font-weight: bold;
}