.App {
    height: 100vh;
    font-family: "Rajdhani";
}

.navLinkContainer  > .navs{
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    padding: 5%;
}

.navLinkContainer:first-child {
    padding-top: 10vh;
}

.bgr {
    min-height: 95vh;
    height: 100%;
    background: url(./assets/bgr.png) repeat-y fixed;
}

.navLinkContainer >  .active {
    width: 100%;
    border-radius: 0 !important;
    background: rgba(231, 231, 231, 0.3) !important;
}

.pre-content {
    background: #e7e7e7e7 fixed;
    min-height: 100vh;
    height: 100%;
    padding-top: 4%;
}

.payments-heading {
    font-weight: bold;
    color: #5E5E5E;
}

.station-name-data {
    font-weight: bold;
    color: #5E5E5E;
}

.thead-row th {
    border-top: none !important;
    color: #B3B3B3;
    font-weight: unset;

}
.logout {
    margin-top: 40vh;
}


.amount-data {
    font-weight: bold;
    color: #5866FD;
    min-width: 9vw;
}

.date-data {
    color: #7D7D7D;
}

.price-data {
    min-width: 6vw;
}

.nav-user-icon {
    margin-left: 7px;
}


.datarow > td {
    padding-top: 0.8% !important;
    padding-bottom: 0.8% !important;
    cursor: pointer;
}

.content-position {
    padding-right: 35%;
    text-align: right;
}

.nexton-logo-li {
    margin-right: 5vw;
}

.no-data-to-display {

    font-weight: bold;
    color: #5E5E5E;
}


@media only screen and (max-height: 900px) {
    .pre-content {
        height: 100%;
    }

}
