.cis-wrapper {
    height: 95vh;
}

.cis-heading {
    font-size: 3em;
    font-weight: bold;
    width: 30vw;
    min-height: 24vh;
    text-align: center;
    color: #8B8B8B;
    padding-top: 10vh;
}

.cis-image {
    margin-top: 8vh;
    height: 40vh;
}

.cis-text p {
    margin-top: 3vh;
    width: 26vw;
    color: #5E5E5E;
}


.cis-btn {
    background: none;
    width: 276px;
    height: 54px;
    border-width: 2px;
    border-color: #4E5EFC;
    border-style: solid;
    border-radius: 6px;
    color: #4E5EFC;
    font-weight: bold;
}

.greyBgr {
    background: #F1F1F1;
}
