.navs{
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    padding: 6%;
}

.logout-btn {
    background: none;
    border:none;
    margin-bottom: 10vh;
    margin-left: 1vw;
}

.logout-btn-wrapper {
    margin-top: 35vh;
}

.logout-btn-wrapper a:hover     {
    text-decoration: none;
}

.nav-icon {
    width: 5vw;
}