.updatePasswordForm {

}

.update-form-wrapper {
    background: #F1F1F1;
}

.up-heading  h1 {
    color: #8B8B8B;
}

.up-field {
    background: #F1F1F1 !important;
}

